export default class WaitUntilFinished {
  _currentFunction;

  _delayTime = 100;
  _eventsStopped = false;
  _startTime = new Date();
  _currentTimeout = null;

  constructor() {
    this._currentFunction = () => {};
  }

  beginWatch = (startTime, fn) => {
    this._startTime = startTime;
    this._currentFunction = fn;

    if (!this._eventsStopped) {
      this._eventsStopped = true;
      this._waitOnFinish();
    }
  };

  _waitOnFinish() {
    if (Number(new Date()) - Number(this._startTime) < this._delayTime) {
      clearTimeout(this._currentTimeout);
      this._currentTimeout = setTimeout(this._waitOnFinish.bind(this), this._delayTime);
    } else {
      this._currentFunction();
      this._eventsStopped = false;
    }
  }
}
