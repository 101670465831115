import React from "react";

const OrangeSection = () => {
  return (
    <div className="orange-section overflow-hidden" style={{ backgroundColor: "" }}>
      <div className="orange-section-image"></div>
    </div>
  );
};

export default OrangeSection;
