import React, { useEffect, useState } from "react";

const ShowMore = ({ callBack, moveOut }) => {
  let [clicked, setClicked] = useState(false);
  const firstClick = () => {
    if (!clicked) {
      setClicked(true);
    }
    callBack();
  };

  useEffect(() => {
    setTimeout(() => {
      setClicked(true);
      callBack();
    }, 5200);
  }, []);

  return (
    <div className="show-more overflow-hidden">
      <div className=" row show-more-animation d-flex">
        {/* <a onClick={() => firstClick()}>
          <img className={`show-more-arrow ${moveOut ? "arrow-rotate" : ""} ${!clicked ? "arrow-pulse" : ""}`} src="/img/arrow.svg"></img>
         
        </a> */}
      </div>
    </div>
  );
};

export default ShowMore;
