import React, { useEffect, useRef } from "react";

const LogoSled = ({ moveOut }) => {
  const itemsRef = useRef([]);

  useEffect(() => {
    let timing = 100;

    for (let i = 0; i < itemsRef.current.length; i++) {
      if (moveOut) {
        setTimeout(() => {
          itemsRef.current[i].classList.remove("logo-sled-link-out");
        }, timing);
        timing += 75;
      }
      itemsRef.current[i].classList.add("logo-sled-link-out");
    }
  }, [moveOut]);

  return (
    <div className={`logo-sled-container ${moveOut ? "sled-enter" : ""} mt-5 `} align="center">
      <span className=" text-white fw-light lh-1">Click one to find out more</span>
      <div ref={(el) => (itemsRef.current[0] = el)} className="logo-sled-link-div logo-sled-link-out " align="center">
        <a href="https://etvsoftware.com" className="pe-auto">
          <img className="logo-sled-link " alt="ETV Software" src="/img/ETV-link.png" />
        </a>
      </div>
      <div ref={(el) => (itemsRef.current[1] = el)} className="logo-sled-link-div logo-sled-link-out " align="center">
        <a href="https://computerrepairintyler.com" className="pe-auto">
          <img className="logo-sled-link " alt="Computer Repair in Tyler" src="/img/CRIT-link.png" />
        </a>
      </div>
      <div ref={(el) => (itemsRef.current[2] = el)} className="logo-sled-link-div logo-sled-link-out" align="center">
        <a href="https://cablingintyler.com/" className="pe-auto">
          <img className="logo-sled-link" src="/img/CIT-link.png"></img>
        </a>
      </div>
      <div ref={(el) => (itemsRef.current[3] = el)} className="logo-sled-link-div logo-sled-link-out" align="center">
        <a href="https://webdesignintyler.com/" className="pe-auto">
          <img className="logo-sled-link" src="/img/WDIT-link.png"></img>
        </a>
      </div>
      <div ref={(el) => (itemsRef.current[4] = el)} className="logo-sled-link-div logo-sled-link-out" align="center">
        <a href="https://true.land/" className="pe-auto">
          <img className="logo-sled-link" src="/img/TL-link.png"></img>
        </a>
      </div>
    </div>
  );
};

export default LogoSled;
