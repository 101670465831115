import React from "react";

const MainLogo = () => {
  return (
    <div className="main-logo position-absolute">
      <div className="row main-logo-animation flex-d justify-content-center justify-content-md-start">
        <object data="/img/etv-logo.svg" alt="ETV Software logo" />
      </div>
    </div>
  );
};

export default MainLogo;
