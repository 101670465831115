import React, { useState, useEffect } from "react";

import TopBlueWedge from "../components/backgroundItems/TopBlueWedge";
import FullGradient from "../components/backgroundItems/FullGradient";
import OrangeSection from "../components/backgroundItems/OrangeSection";
import BottomBlueWedge from "../components/backgroundItems/BottomBlueWedge";
import YBTSComponent from "../components/YBTS";
import MainLogo from "../components/MainLogo";
import TopServices from "../components/TopServices";
import ShowMore from "../components/ShowMore";
import LogoSled from "../components/LogoSled";
import CopyWrite from "../components/CopyWrite";
import WaitUntilFinished from "../helpers/WindowHeightAdjust";

const PageLayout = () => {
  const [waitUntilFinished, setWaitUntilFinished] = useState(new WaitUntilFinished());
  const [moveOut, setMoveOut] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", windowHelper.bind(this));
    setWaitUntilFinished(new WaitUntilFinished());

    return () => {
      window.removeEventListener("resize", windowHelper.bind(this));
    };
  }, []);

  const windowHelper = () => {
    waitUntilFinished.beginWatch(new Date(), windowHeightAdjustment);
  };

  const windowHeightAdjustment = () => {
    const doc = document.documentElement;

    doc.style.setProperty("--app-height-calc", `${window.innerHeight}px`);
    // console.log(doc.style.getPropertyValue("--app-height-calc"));
  };

  const move = () => {
    setMoveOut(!moveOut);
  };

  return (
    <div>
      <div className="  page-layout overflow-hidden">
        <OrangeSection moveOut={moveOut} />
        <LogoSled moveOut={moveOut} />
        <CopyWrite />
        <BottomBlueWedge moveOut={moveOut} />
        <TopBlueWedge moveOut={moveOut} />

        {/* <FullGradient /> */}
        <MainLogo />
        <YBTSComponent moveOut={moveOut} />
        <ShowMore callBack={move} moveOut={moveOut} />
        {/* <TopServices /> */}
      </div>
    </div>
  );
};

export default PageLayout;
