import React from "react";

const YBTSComponent = ({ moveOut }) => {
  return (
    <div className={` ytbs-main `}>
      <div className={`ytbs-inner-container ${moveOut ? "ybts-exit" : ""}`}>
        <div className="ytbs-animation">
          <object data="/img/YBTS.svg"></object>
        </div>
      </div>
    </div>
  );
};

export default YBTSComponent;
